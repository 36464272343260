import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
// require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


// $(document).foundation();

$(document).ready(function() {

    /* Toggle mobile navbar */
    let toggleNavbar = document.querySelector('.header_toggle'),
        navbar = document.querySelector('.header_navbar');

    /* Mark current nav bar link as active */
    let navbarLinks = document.querySelectorAll('.header_nav a');
    if (navbarLinks) {
        navbarLinks.forEach(navBarLink => {
            if (navBarLink.href === window.location.href) {
                navBarLink.classList.add('active');
            }
        });
    }

    if (toggleNavbar) {
        toggleNavbar.addEventListener('click', event => {
            if (toggleNavbar.classList.contains('active')) {
                toggleNavbar.classList.remove('active');
                navbar.classList.remove('active');
                $.scrollLock(false);
            }
            else {
                toggleNavbar.classList.add('active');
                navbar.classList.add('active');
                $.scrollLock(true);
            }
        });
    }

    window.addEventListener('resize', event => {
        if (window.getComputedStyle(toggleNavbar).display === 'none') {
            if (toggleNavbar.classList.contains('active')) {
                $.scrollLock(false);
            }

            toggleNavbar.classList.remove('active');
            navbar.classList.remove('active');
        }      
    });

    /* Header sticky */
    window.addEventListener('scroll', event => {
        if (window.scrollY > 0) {
            document.querySelector('.header').classList.add('sticky');
        }
        else {
            document.querySelector('.header').classList.remove('sticky');
        }
    });

    /* Login dropdown */
    let toggleLogin = document.querySelector('.header_login');

    if (toggleLogin) {
        toggleLogin.addEventListener('click', event => {
            event.preventDefault();
            
            if (toggleLogin.classList.contains('active')) {
                toggleLogin.classList.remove('active');
                document.querySelector('.header_dropdown').classList.remove('visible');
            }
            else {
                toggleLogin.classList.add('active');
                document.querySelector('.header_dropdown').classList.add('visible');
            }
        });

        window.addEventListener('click', event => {
            if (!event.target.closest('.header_dropdown, .header_login')) {
                toggleLogin.classList.remove('active');
                document.querySelector('.header_dropdown').classList.remove('visible');
            }
        });
    }

    /* Image rotation for platform page */
    let heightImage = $(".image-tabs__layout-container").innerHeight();
    
    $('.check-tabs-button').on('click', function(sp) {
        sp.preventDefault();
        let pushContentFor = $(this).attr('data-tabs') * heightImage;
        $('.image-tabs__layout').css('top','-' + pushContentFor + 'px');
        $('.active-tabs').removeClass('active-tabs');
        $(this).addClass('active-tabs');
    });
    
    $(window).on('resize load', function() {
        if(window.innerWidth < 767) {
            if(!$('.mkTabs').hasClass('mobileViewTabs')) {
                $('div[data-tabs-number]').each(function() {

                    let nodeNumber = $(this).attr('data-tabs-number'),
                            parentNode = $('.link-tabs__module[data-tabs="'+nodeNumber+'"]');
                    $(this).insertBefore(parentNode);
                    $('.mkTabs').removeClass('desktopViewTabs');
                    $('.mkTabs').addClass('mobileViewTabs');
                });
            }
        } else {
            if(!$('.mkTabs').hasClass('desktopViewTabs')) {
                //let tabsIndex = 0;
                $('div[data-tabs-number]').each(function() {

                    let nodeNumber = $(this).attr('data-tabs-number'),
                            parentNode = $('');
                    
                    
                    if(nodeNumber == 0) {
                        $(this).appendTo('.image-tabs__layout');
                    } else {
                        let nodeNumber = $(this).attr('data-tabs-number') - 1
                        $(this).insertAfter('.image-tabs__layout-container[data-tabs-number="'+nodeNumber+'"]');
                    }
                    
                    
                    $('.mkTabs').removeClass('mobileViewTabs');
                    $('.mkTabs').addClass('desktopViewTabs');
                });
            }
        }
    });

    /* Slider */
    let swiperTestimonials = new Swiper('.testimonials_slider', {
        loop: false,
        watchOverflow: true,
        slidesPerView: 1,
        spaceBetween: 100,
        speed: 500,
        autoplay: {
            delay: 8000
        },
        pagination: {
            el: '.testimonials_pagination',
            clickable: true
        },
        breakpoints: {
            1200: {
                slidesPerView: 2,
                spaceBetween: 170,
            },
            992: {
                spaceBetween: 100
            }
        }
    });

    let swiperCaseStudies = new Swiper('.case_studies_slider', {
        loop: false,
        watchOverflow: true,
        slidesPerView: 1,
        spaceBetween: 45,
        speed: 500,
        autoplay: {
            delay: 8000
        },
        pagination: {
            el: '.case_studies_pagination',
            clickable: true
        },
        breakpoints: {
            1200: {
                slidesPerView: 2,
                spaceBetween: 260,
            },
            992: {
                slidesPerView: 2,
                spaceBetween: 45
            },
            768: {
                slidesPerView: 2
            }
        }
    });

    /* Animation scroll */
    if (document.querySelector('.wow')) {
        new WOW().init();
    }

    /* Accordion */
    const accordionButtons = document.querySelectorAll('.accordion_trigger');

    accordionButtons.forEach(function(el) {
        let accordionContainer;

        el.addEventListener('click', event => {
            accordionContainer = el.nextElementSibling;

            let expandedPanel = el.closest('.accordion').querySelector('.accordion_trigger.active');

            if (!el.classList.contains('active')) {
                if (expandedPanel) {
                    expandedPanel.classList.remove('active');
                    expandedPanel.nextElementSibling.style.maxHeight = null;
                }
                
                el.classList.add('active');
                accordionContainer.style.maxHeight = accordionContainer.scrollHeight + "px";
            }
            else {
                el.classList.remove('active');
                accordionContainer.style.maxHeight = null;
            }
        });

        activeAccordionElem(el);

        window.addEventListener('resize', event => {
            activeAccordionElem(el);
        });
    }); 

    function activeAccordionElem(trigger) {
        if (trigger.classList.contains('active')) {
            let accordionContainer = trigger.nextElementSibling;
            accordionContainer.style.maxHeight = accordionContainer.scrollHeight + "px";
        }
    }

});

